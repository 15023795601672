export default function handleHeader() {
  const logo = document.querySelector('#js-header-logo');

  /**
  * 各フレームでの処理
  * @param time
  */
  const moveAnimation = () => {
    if(window.scrollY <= window.innerHeight) {
      logo.classList.remove('is-active');
    }else{
      logo.classList.add('is-active');
    }
    requestAnimationFrame(moveAnimation);
  }

  moveAnimation();
}