declare var $: any;

// こちらはメソッド読み込みのimport
import 'slick-carousel'

export default function handleFooterSlick() {
  let timeoutId;
  
  function activateFooterSlider() {
    $('#js-footer-slider').not('.slick-initialized').slick({
      infinite: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 3,
      slidesToScroll: 1,
      slidespeed: 3500,
      pauseOnHover: false,
      responsive: [{
        breakpoint : 768,
        settings: {
          infinite: true,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          slidespeed: 3500,
          pauseOnHover: false,
        }
      }]
    });
  }

  window.addEventListener('resize', () => {
    if(timeoutId) return;

    timeoutId = setTimeout( () => {
      timeoutId = 0;
    }, 500 );

    activateFooterSlider();
  });

  activateFooterSlider();
}