declare var $: any;

export default function handleMenu(){
  const drawer = '#js-drawer';
  const menuBody = '#js-menu-body'
  $(drawer).on('click', function(){
    toggleMenu();
  });

  $('#js-menu-overlay').on('click', function(){
    toggleMenu();
  });

  function toggleMenu() {
    if($(menuBody).attr('aria-hidden') === 'true'){
      $(menuBody).attr('aria-hidden', 'false');
    }else{
      $(menuBody).attr('aria-hidden', 'true');
    }

    $(drawer).toggleClass('is-active');
    $(menuBody).toggleClass('is-open');
  }
}