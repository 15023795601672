declare var $: any;

import MicroModal from '../../../node_modules/micromodal/dist/micromodal';

export default function handleMicroModal(){
  MicroModal.init({
    onClose: modal => {
      const $player = $(`#${modal.id} .js-movie-iframe`);
      if($player.length){
        const $playerWindow = $player[0].contentWindow;
        $playerWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      }
    },
    disableScroll: true,
    disableFocus: false,
    awaitOpenAnimation: false,
    awaitCloseAnimation: false,
  });
}