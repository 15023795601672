declare var $: any;

export default function handleLoading() {
  const loadingContainer = $('#js-loading-container');
  if(loadingContainer.length){
    window.onload = function() {
      loadingContainer.removeClass('is-active');
    }
    setTimeout(function() {
      loadingContainer.removeClass('is-active');
    }, 2000);
  }
}