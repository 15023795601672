declare var $: any;

export default function handlePositionWithHash() {
  $(window).on('load', function() {
    let headerHeight = $('#js-header-logo').outerHeight();
    const urlHash = location.hash;
    if(urlHash) {
      let position = $(urlHash).offset().top - headerHeight;
      $('html, body').animate({ scrollTop: position }, 200, 'swing');
    }
  });
}