export default function handleTabs() {
  window.addEventListener("DOMContentLoaded", () => {
    // すべてのタブに対してクリックイベントを用意
    const tabs = document.querySelectorAll('[role="tab"]');
    tabs.forEach(tab => {
      tab.addEventListener("click", changeTabs);
    })

    function changeTabs(e) {
      const target = e.target;
      const tabsParent = target.parentNode;
      const tabsGrandparent = tabsParent.parentNode;
      const panelId = target.getAttribute('aria-controls');
      const panelElement = document.getElementById(panelId);
      const paginationElement = document.querySelector(".pagination");

      if(target.getAttribute('aria-selected') === 'true') {
        // 非表示にする（非表示にしない場合は[aria-selected="true"]のときにpointer-events: noneを付与する）
        target.setAttribute("aria-selected", false);
        panelElement.classList.remove('is-active');
      } else {
        // tabエリアを表示
        tabsGrandparent.querySelectorAll('[role="tab"]').forEach(p => p.setAttribute("aria-selected", false));
        target.setAttribute("aria-selected", true);

        // paginationが「すべて」のみに表示されるため
        if(paginationElement){
          if(panelId === "tab-event-all" || panelId === "tab-news-all" || panelId === "tab-movie-all" || panelId === "tab-attraction-all" || panelId === "tab-animals-all") {
            paginationElement.classList.remove('u-none');
          }else{
            paginationElement.classList.add('u-none');
          }
        }

        // tabpanelエリアを表示
        document.querySelectorAll('[role="tabpanel"]').forEach(p => p.classList.remove("is-active"));
        panelElement.classList.add('is-active');
      }
    }
  })
}