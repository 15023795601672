declare var $: any;

export default function scrollToInternalLink() {
  $('a[href^="#"]').click(function(){
    if($(this).data('month')) return;
    var adjust = 200;
    var speed = 200;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top - adjust;
    $('body,html').animate({scrollTop:position}, speed, 'swing');
    return false;
  });
}