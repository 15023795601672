declare var $: any;
// こちらはメソッド読み込みのimport
import 'slick-carousel'

export default function handleHeroSlick() {
  window.addEventListener('load', () => {
    $('#js-slick').slick({
      infinite: true,
      arrows: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      slidespeed: 3500,
      pauseOnHover: false,
      responsive: [{
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }]
    });
  });
}