import adjustViewport from './modules/_adjustViewport';
import adjustViewHeight from './modules/_adjustViewHeight';
import scrollToInternalLink from './modules/_scrollToInternalLink';
import handleMicroModal from './modules/_handleMicromodal';
import handleHeroSlick from './modules/_handleHeroSlick';
import handleHeader from './modules/_handleHeader';
import handleTabs from './modules/_handleTabs';
import handleFooterSlick from './modules/_handleFooterSlick';
import handleMenu from './modules/_handleMenu';
import handleHeaderPosition from './modules/_handleHeaderPosition';
import handleLoading from './modules/_handleLoading';
import handlePositionWithHash from './modules/_handlePositionWithHash';

adjustViewport();
adjustViewHeight();
scrollToInternalLink();
handleMicroModal();
handleMenu();
handleHeroSlick();
handleHeader();
handleTabs();
handleFooterSlick();
handleHeaderPosition();
handleLoading();
handlePositionWithHash();